<!-- faq section -->
<section id="faq" class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="faq-block">
                    <div>
                        <h3 class="frequent-text"><strong>{{'titleFAQ' | translate}}</strong></h3>
                        <div class="card faq-list">

                            <!-- Start FAQ 1 -->
                            <div class="card-header" (click)="openItem('.faq_content_1')">
                                <div class="d-flex">
                                    <div class="faq-icon">
                                        <i class="fa fa-angle-right rotate"></i> 
                                    </div>
                                    <h6 class="mb-0 flex-fill"><strong>{{'faqs.0.title' | translate}}</strong></h6>
                                </div>
                            </div>
                            <div class="card-body faq_content_1">
                                <div class="d-flex">
                                    <div class="faq-icon"></div>
                                    <div [innerHTML]="'faqs.0.description' | translate"></div>
                                </div>
                            </div>
                            <!-- End FAQ 1 -->

                            <!-- Start FAQ 2 -->
                            <div class="card-header" (click)="openItem('.faq_content_2')">
                                <div class="d-flex">
                                    <div class="faq-icon">
                                        <i class="fa fa-angle-right rotate"></i> 
                                    </div>
                                    <h6 class="mb-0 flex-fill"><strong>{{'faqs.1.title' | translate}}</strong></h6>
                                </div>
                            </div>
                            <div class="card-body faq_content_2">
                                <div class="d-flex">
                                    <div class="faq-icon"></div>
                                    <div [innerHTML]="'faqs.1.description' | translate"></div>
                                </div>
                            </div>
                             <!-- Start FAQ 2 -->

                             <!-- Start FAQ 3 -->
                            <div class="card-header" (click)="openItem('.faq_content_3')">
                                <div class="d-flex">
                                    <div class="faq-icon">
                                        <i class="fa fa-angle-right rotate"></i> 
                                    </div>
                                    <h6 class="mb-0 flex-fill"><strong>{{'faqs.2.title' | translate}}</strong></h6>
                                </div>
                            </div>
                            <div class="card-body faq_content_3">
                                <div class="d-flex">
                                    <div class="faq-icon"></div>
                                    <div [innerHTML]="'faqs.2.description' | translate"></div>
                                </div>
                            </div>
                             <!-- Start FAQ 3 -->

                              <!-- Start FAQ 4 -->
                            <div class="card-header" (click)="openItem('.faq_content_4')">
                                <div class="d-flex">
                                    <div class="faq-icon">
                                        <i class="fa fa-angle-right rotate"></i> 
                                    </div>
                                    <h6 class="mb-0 flex-fill"><strong>{{'faqs.3.title' | translate}}</strong></h6>
                                </div>
                            </div>
                            <div class="card-body faq_content_4">
                                <div class="d-flex">
                                    <div class="faq-icon"></div>
                                    <div [innerHTML]="'faqs.3.description' | translate"></div>
                                </div>
                            </div>
                             <!-- Start FAQ 4 -->

                             <!-- Start FAQ 5 -->
                            <div class="card-header" (click)="openItem('.faq_content_5')">
                                <div class="d-flex">
                                    <div class="faq-icon">
                                        <i class="fa fa-angle-right rotate"></i> 
                                    </div>
                                    <h6 class="mb-0 flex-fill"><strong>{{'faqs.4.title' | translate}}</strong></h6>
                                </div>
                            </div>
                            <div class="card-body faq_content_5">
                                <div class="d-flex">
                                    <div class="faq-icon"></div>
                                    <div [innerHTML]="'faqs.4.description' | translate"></div>
                                </div>
                            </div>
                             <!-- Start FAQ 5 -->

                              <!-- Start FAQ 6 -->
                            <div class="card-header" (click)="openItem('.faq_content_6')">
                                <div class="d-flex">
                                    <div class="faq-icon">
                                        <i class="fa fa-angle-right rotate"></i> 
                                    </div>
                                    <h6 class="mb-0 flex-fill"><strong>{{'faqs.5.title' | translate}}</strong></h6>
                                </div>
                            </div>
                            <div class="card-body faq_content_6">
                                <div class="d-flex">
                                    <div class="faq-icon"></div>
                                    <div [innerHTML]="'faqs.5.description' | translate"></div>
                                </div>
                            </div>
                             <!-- Start FAQ 6 -->

                              <!-- Start FAQ 7 -->
                            <div class="card-header" (click)="openItem('.faq_content_7')">
                                <div class="d-flex">
                                    <div class="faq-icon">
                                        <i class="fa fa-angle-right rotate"></i> 
                                    </div>
                                    <h6 class="mb-0 flex-fill"><strong>{{'faqs.6.title' | translate}}</strong></h6>
                                </div>
                            </div>
                            <div class="card-body faq_content_7">
                                <div class="d-flex">
                                    <div class="faq-icon"></div>
                                    <div [innerHTML]="'faqs.6.description' | translate"></div>
                                </div>
                            </div>
                             <!-- Start FAQ 7 -->
                             
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/saas1/faq-img.png" class="img-webp img-fluid" alt="faq-person">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end faq section -->
