import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-enterprice-sass-testimonial',
  templateUrl: './enterprice-sass-testimonial.component.html',
  styleUrls: ['./enterprice-sass-testimonial.component.scss']
})
export class EnterpriceSassTestimonialComponent implements OnInit {
	public users = [
		{ 
			img:"assets/images/agency/testimonial/1.png",
			name:"Chris du Plessis",
			designation:"Photos Of Africa",
			link: "https://www.photosofafrica.com/",
			review:"StreetBuilder is the best ever platform to create Blue Lines on Street View with any 360 camera with or without GPS. My clients are happy with the results! Google Street View Trusted Photographer in Africa since 2013"
		},
		{ 
			img:"assets/images/agency/testimonial/2.png",
			name:"Gazaly Samsadeen",
			designation:"Dotcom Systems Pvt Ltd",
			link: "http://dotcomsystems.lk/streetview",
			review:"GoThru Street Builder is what I was searching after I started to shoot Street View Video with a 360 Camera. Friendly UI, Less Buttons with more features. Extracting frames is one of the best feature of the application. Very powerful tool for street view publishing .I really love it."
		},
		{ 
			img:"assets/images/agency/testimonial/3.png",
			name:"Guy Couture",
			designation:"Maps360.ca",
			link: "https://maps360.ca",
			review:"I absolutely love StreetBuilder! I’m very impressed with how much attention, interest and support I received from GoThru team. I am still amazed at what we received for the price that we paid. I would highly recommend to use StreetBuilder! It’s really help us grow!"
		}
	];

	public testimonialCarouselOptions = {
		items: 3,
		margin: 65,
		nav: true,
		dots: false,
		navText: ['<img class="img-webp" src="/assets/images/agency/testimonial/left.png">', '<img class="img-webp" src="/assets/images/agency/testimonial/right.png">'],
		autoplay: false,
		slideSpeed: 300,
		paginationSpeed: 400,
		loop: true,
		responsive: {
			0: {
				items: 1,
				margin: 10
			},
			575: {
				items:2,
				margin: 10
			},
			991: {
				items: 2,
				margin: 30
			},
			1199: {
				items: 3,
				margin: 30
			}
		}
	}

	constructor(){}

	ngOnInit() {
	}

	ngAfterViewInit(){
		setTimeout(()=>{
			try{$('.owl-carousel').owlCarousel(this.testimonialCarouselOptions);}catch(err){}
		}, 1000);
	}

	
  
	

}
