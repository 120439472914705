<!-- price table -->
<section id="pricing" class="saas1 pricing" style="background-color: #f7f7f7;">
    <div class="container">
        <div class="col-md-10 offset-md-1 text-center">
            <div class="title">
                <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid img-webp">
                <div class="main-title">
                    <h2 [innerHTML]="'titlePricing' | translate"></h2>
                </div>
                <hr>
                <div class="sub-title">
                    <p class="p-padding mb-2" [innerHTML]="'contentPricing1' | translate"></p>
                    <p class="p-padding mb-2" [innerHTML]="'contentPricing2' | translate"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row wrap-pricing">

            <!--  Start 01 -->
            <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="item-pricing" style="background-color: #ffffff;">
                    <h5 class="price-heading" style="font-size: 22px;"><strong>{{'price.0.heading' | translate}}</strong></h5>
                    <img src="assets/images/saas1/plan-box.png" class="img-webp img-line" alt="pan-line">
                    <h4 class="no-weight"><span>{{'price.0.price' | translate}}</span>/{{'monthPrice' | translate}}</h4>
                    <ul class="list-item">
                        <li class="price_f0">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f0')">
                                        <strong>{{'price.0.features.0.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.0.features.0.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f1">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f1')">
                                        <strong>{{'price.0.features.1.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.0.features.1.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f2">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f2')">
                                        <strong>{{'price.0.features.2.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.0.features.2.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f3">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f3')">
                                        <strong>{{'price.0.features.3.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.0.features.3.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f4">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f4')">
                                        <strong>{{'price.0.features.4.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.0.features.4.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f5">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f5')">
                                        <strong>{{'price.0.features.5.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.0.features.5.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f6">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f6')">
                                        <strong>{{'price.0.features.6.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.0.features.6.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <a class="btn btn-sm btn-default btn-white" target="_BLANK" href="https://gothru.co/">{{'price.0.btn' | translate}}</a>
                </div>
            </div>
            <!--  End 01 -->

            <!--  Start 02 -->
            <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="item-pricing" style="background-color: #ffffff;">
                    <h5 class="price-heading" style="font-size: 22px;"><strong>{{'price.1.heading' | translate}}</strong></h5>
                    <img src="assets/images/saas1/plan-box.png" class=" img-webp img-line" alt="pan-line">
                    <h4 class="no-weight"><span>{{'price.1.price' | translate}}</span>/{{'monthPrice' | translate}}</h4>
                    <ul class="list-item">
                        <li class="price_f7">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f7')">
                                        <strong>{{'price.1.features.0.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.1.features.0.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f8">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f8')">
                                        <strong>{{'price.1.features.1.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.1.features.1.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f9">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f9')">
                                        <strong>{{'price.1.features.2.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.1.features.2.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f10">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f10')">
                                        <strong>{{'price.1.features.3.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.1.features.3.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f11">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f11')">
                                        <strong>{{'price.1.features.4.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.1.features.4.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f12">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f12')">
                                        <strong>{{'price.1.features.5.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.1.features.5.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <a class="btn btn-sm btn-default btn-white" target="_BLANK" href="https://gothru.co/">{{'price.1.btn' | translate}}</a>
                </div>
            </div>
            <!--  End 02 -->

            <!--  Start 03 -->
            <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="item-pricing" style="background-color: #ffffff;">
                    <h5 class="price-heading" style="font-size: 22px;"><strong>{{'price.2.heading' | translate}}</strong></h5>
                    <img  src="assets/images/saas1/plan-box.png" class="img-line img-webp" alt="pan-line">
                    <h4 class="no-weight"><span>{{'price.2.price' | translate}}</span>/{{'monthPrice' | translate}}</h4>
                    <ul class="list-item">
                        <li class="price_f13">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f13')">
                                        <strong>{{'price.2.features.0.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.2.features.0.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f14">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f14')">
                                        <strong>{{'price.2.features.1.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.2.features.1.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f15">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f15')">
                                        <strong>{{'price.2.features.2.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.2.features.2.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f16">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f16')">
                                        <strong>{{'price.2.features.3.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.2.features.3.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f17">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f17')">
                                        <strong>{{'price.2.features.4.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.2.features.4.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f18">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f18')">
                                        <strong>{{'price.2.features.5.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.2.features.5.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <a class="btn btn-sm btn-default btn-white" target="_BLANK" href="https://gothru.co/">{{'price.2.btn' | translate}}</a>
                </div>
            </div>
            <!--  End 03 -->

            <!--  Start 04 -->
            <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="item-pricing" style="background-color: #ffffff;">
                    <h5 class="price-heading" style="font-size: 22px;"><strong>{{'price.3.heading' | translate}}</strong></h5>
                    <img src="assets/images/saas1/plan-box.png" class="img-webp img-line" alt="pan-line">
                    <h4 class="no-weight"><span>{{'price.3.price' | translate}}</span>/{{'monthPrice' | translate}}</h4>
                    <ul class="list-item">
                        <li class="price_f19">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f19')">
                                        <strong>{{'price.3.features.0.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.3.features.0.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f20">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f20')">
                                        <strong>{{'price.3.features.1.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.3.features.1.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f21">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f21')">
                                        <strong>{{'price.3.features.2.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.3.features.2.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f22">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f22')">
                                        <strong>{{'price.3.features.3.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.3.features.3.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f23">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f23')">
                                        <strong>{{'price.3.features.4.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.3.features.4.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                        <li class="price_f24">
                            <div class="d-flex">
                                <div style="width: 20px; min-width: 20px;">
                                    <i class="fa fa-angle-right" style="font-size: 16px;"></i>
                                    <i class="fa fa-angle-down" style="font-size: 16px;"></i>
                                </div>
                                <div class="flex-fill">
                                    <a href="javascript:;" (click)="openItem('.price_f24')">
                                        <strong>{{'price.3.features.5.label' | translate}}</strong>
                                    </a>
                                    <div class="content-feature" [innerHTML]="'price.3.features.5.description' | translate"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <a class="btn btn-sm btn-default btn-white" target="_BLANK" href="https://gothru.co/">{{'price.3.btn' | translate}}</a>
                </div>
            </div>
            <!--  End 04 -->
        </div>
    </div>
</section>
<!-- end price table -->
