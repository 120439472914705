import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslatesService, ILang } from '@shared/translates';
import * as _ from 'lodash';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
	public openSide : boolean = false;
	public activeItem: string = 'home';
	public active: boolean = false;
	public activeChildItem : string = '' 
	public overlay: boolean = false;
	public isOpen: boolean = false;
	public openSubMenu0: boolean = true;
	public openSubMenu1: boolean = false;
	public openSubMenu2: boolean = false;
	public openSubMenu3: boolean = false;
	public openSubMenu4: boolean = false;
	public openSubMenu5: boolean = false;
	public langList$: Observable<ILang[]>;
	public currentLang: string;
	public showDropdown: boolean = false;

	constructor(
		private router: Router,
		private _translatesService: TranslatesService,
	) { }

	ngOnInit() {
		this.langList$ = this._translatesService.getLangList();
		this.currentLang = this._translatesService.getCurrentLang();
	}

	ngAfterViewInit(){}

	public changeLang(code: string): void {
		this.currentLang = code;
		this._translatesService.changeLang(code);
	}

	toggleSidebar(){
		this.openSide = !this.openSide
	}

	closeOverlay(){
		this.openSide = false
	}

	scrollTo(e){
		if(this.router.url === '/'){
			this.gotoSection(e);
		}else{
			this.router.navigate(['/']);
		}
	}

	gotoSection(e){
		var positionElement = document.getElementById(e).offsetTop;
		let endPosition = document.body.offsetHeight - window.innerHeight - 50;
		let scrollToTop = window.setInterval(() => {
			let pos = window.pageYOffset;
			if(pos > positionElement || pos >= endPosition) {
				window.clearInterval(scrollToTop);
			}else{
				window.scrollTo(0, pos + 50); 
			}
		}, 10);

		window.onscroll = function (e) {  
			if(this.oldScroll > this.scrollY){
				window.clearInterval(scrollToTop);
			}
  			this.oldScroll = this.scrollY;
		} 
		
		this.openSide = false;
	}

	openDropdown(){
		this.isOpen = !this.isOpen;
	}
}
