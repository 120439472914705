import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service'

@Component({
  selector: 'app-enterprice-sass-header',
  templateUrl: './enterprice-sass-header.component.html',
  styleUrls: ['./enterprice-sass-header.component.scss'],
  providers: [HeaderService]
})
export class EnterpriceSassHeaderComponent implements OnInit {
	public link1:string = null;
	public link2:string = null;

	constructor(
		private HeaderService: HeaderService
	){}

	ngOnInit() {
		this.HeaderService.getJsonData().subscribe(res =>{
			this.link1 = res['downloadMac'];
			this.link2 = res['downloadExe'];
		});
	}
	  
}
