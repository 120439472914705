import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-enterprice-sass-faq',
  templateUrl: './enterprice-sass-faq.component.html',
  styleUrls: ['./enterprice-sass-faq.component.scss']
})
export class EnterpriceSassFaqComponent implements OnInit {

	constructor() {}

	ngOnInit() {}

	openItem(e){
		try{
			$(e).toggleClass('active');
		}catch(err){}
	}

}
