import { NgModule } from '@angular/core';
import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export function metaFactory(translate: TranslateService): MetaLoader {
  return new MetaStaticLoader({
    callback: (key: string): Observable<string | Object> => translate.get(key),
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: '',
    applicationName: '',
    defaults: {
      title: 'Default page title',
      description: 'Default description',
      'og:site_name': 'App site Universal',
      'og:type': 'website',
      'og:locale': 'ru_RU',
      'og:locale:alternate': [
        { code: 'da', name: 'Danish', culture: 'da-DK' },
        { code: 'de', name: 'German', culture: 'de-DE' },
        { code: 'en', name: 'English', culture: 'en-US' },
        { code: 'es', name: 'Spanish', culture: 'es-ES' },
        { code: 'fr', name: 'French', culture: 'fr-FR' },
        { code: 'it', name: 'Italian', culture: 'it-IT' },
        { code: 'jp', name: 'Japanese', culture: 'ja-JP' },
        { code: 'nl', name: 'Dutch', culture: 'nl-NL' },
        { code: 'pl', name: 'Polish', culture: 'pl-PL' },
        { code: 'pt', name: 'Portuguese', culture: 'pt-PT' },
        { code: 'ru', name: 'Russian', culture: 'ru-RU' },
        { code: 'sk', name: 'Slovak', culture: 'sk-SK' },
        { code: 'sv', name: 'Swedish', culture: 'sv-SE' },
        { code: 'zh', name: 'Chinese', culture: 'zh-CN' },
      ]
        .map((lang: any) => lang.culture)
        .toString(),
    },
  });
}

@NgModule({
  imports: [
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
      deps: [TranslateService],
    }),
  ],
})
export class SharedMetaModule {}
