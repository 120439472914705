<!--copyright start-->
<div class="saas1 copyright">
    <div class="container text-center">
        <h6 class="copyright-text text-white op-text">
            Copyright ©2020 Streetbuilder <i class="fa fa-heart" aria-hidden="true"></i> GoThru
        </h6>
    </div>
</div>
<!--copyright end-->

<app-tap-to-top></app-tap-to-top>