import { Component, OnInit,  HostListener } from '@angular/core';

@Component({
  selector: 'app-tap-to-top',
  templateUrl: './tap-to-top.component.html',
  styleUrls: ['./tap-to-top.component.scss']
})
export class TapToTopComponent implements OnInit {
	public showScroll: boolean;
	public showScrollHeight = 500;
	public hideScrollHeight = 10;

	constructor() { }

	ngOnInit() {
	}

	onActivate() {
		let scrollToTop = window.setInterval(() => {
		let pos = window.pageYOffset;
		if (pos > 0) {
			window.scrollTo(0, pos - 200); // how far to scroll on each step
		} else {
			window.clearInterval(scrollToTop);
		}
	}, 16);
}

@HostListener('window:scroll', [])
    onWindowScroll(){
		if (( window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight){
			this.showScroll = true;
		} else if ( this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight){ 
			this.showScroll = false; 
		}
    }
}
