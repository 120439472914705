import { Component, OnInit } from '@angular/core';
import { MainService } from './shared/service/main.service';

import { Router, NavigationEnd } from '@angular/router';


@Component({
  	selector: 'app-root',
  	template: `
		<router-outlet></router-outlet>
	`,
	providers: [MainService]
})
export class AppComponent implements OnInit {
	constructor(
		private mainService: MainService,
		private router: Router
	){
		document.addEventListener('visibilitychange',(e)=>{
			if(document.visibilityState === 'visible'){
				this.setIcon('./assets/images/favicon.ico');
			}else{
				this.setIcon('./assets/images/favicon.ico');
			}
		});
	}

	ngOnInit(){
		this.mainService.updateLog().subscribe(res =>{
			console.log('ok');
		}, err =>{
			console.log('not');
		});
	}

	ngAfterViewInit(){
		this.setIcon('./assets/images/favicon.ico');
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				setTimeout(() => {
					this.mainService.changeImgFormat();
				}, 3000);
			}
		})
	}
	setIcon(src){
		var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link['type'] = 'image/x-icon';
		link['rel'] = 'shortcut icon';
		link['href'] = src;
		if(document.querySelector("link[rel*='icon']") === null) document.getElementsByTagName('head')[0].appendChild(link);
	}

	
}
