<!-- Navbar section Start-->
<header class="saas1 header-fixed loding-header position-absolute  custom-scroll pt-sm-0">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav class="pt-3">
                    <a href="javascript:void()" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/brand.png" alt="" class="img-fluid brand-logo img-webp">
                    </a>
                <app-menu></app-menu>
            </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navbar section end-->
