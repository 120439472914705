import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-enterprice-sass-price',
  templateUrl: './enterprice-sass-price.component.html',
  styleUrls: ['./enterprice-sass-price.component.scss']
})
export class EnterpriceSassPriceComponent implements OnInit {
	public price = [];
	public isCollapsed: boolean = false;

	constructor() {}

	ngOnInit(){}

	ngAfterViewInit(){
		setTimeout(()=>{ 
			try{
				$('.list-item').matchHeight(); 
			}catch(err){}
		}, 1000);
	}

	openItem(e){
		try{
			$(e).toggleClass('active');
			$('.list-item').css('height','');
			setTimeout(()=>{ 
				$('.list-item').matchHeight();
				window.dispatchEvent(new Event('resize'));
			},10);
		}catch(err){}
	}
}
