<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h1 class="bg-webp" data-image-src="assets/images/text">404</h1>
        </div>
        <h2 class="mb-4">Oops! This Page Could Not Be Found</h2>
        <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
        <a href="/" class="btn btn-default">Home</a>
    </div>
</div>
    