import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import * as moment from 'moment';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { isMobile, isTablet } from 'mobile-device-detect';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class MainService {
	private baseURL = "https://apiw.gothru.co";

	constructor(
		private http: HttpClient
	) { }

	// ======================== // 
	// Authentication
	// ====================== //
	auth(){
		const options = {
			headers: new HttpHeaders({'accept':  'application/json'})
		};
		return this.http.get(`${this.baseURL}/auth/${Base64.encode('username=gothru&password=kKyfmrKVGG')}`,options);
	}

	// ======================== // 
	// Update Log
	// ====================== //
	updateLog(){
		let device = "desktop";
        if(isTablet)device = "tablet"; 
        if(isMobile && !isTablet) device = "mobile";

        let getReferel = _.includes(document.referrer,window.location.origin) ? '' : document.referrer;
        let obj = {
            type: "visit",
            location: window.location.href,
            referer: getReferel,
            language: navigator.language,
            session: Math.random().toString(36).substr(2, 15),
            width: window.screen.width,
            height: window.screen.height,
            local_time: moment().toISOString(),
            browser: navigator.userAgent,
            site: "streetbuilder",
            device: device,
            host: "streetbuilder.co",
            time: moment().unix()
		};
		
        const options = {
			headers: new HttpHeaders({'accept':  'application/json'})
		};
		return this.http.post(`${this.baseURL}/tours/stats`, obj, options);
	}

	// =================================
	// change img format
	// ==================================
	changeImgFormat(){
		let elem = document.createElement('canvas');
		if (!!(elem.getContext && elem.getContext('2d'))) {
			if(elem.toDataURL('image/webp').indexOf('data:image/webp') == 0){
				$('.img-webp').each(function(){
					let src = $(this).attr('src').replace('png','').replace('webp','').replace('jpg','').replace('jpeg','');
					$(this).attr('src',src + 'webp');
				});
				$('.bg-webp').each(function(){
					let src = $(this).data('image-src');
					$(this).css('background-image', 'url("' + src + '.webp")');
				});
			}
		}
	}
}
