import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslatesService, ILang } from '@shared/translates';
import { MetaService } from '@ngx-meta/core';
import { ColorScssService } from '../shared/service/color-scss.service'
import { MainService } from '@shared/service/main.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
	public langList$: Observable<ILang[]>;
	public currentLang: string;
	public errorMessage: any;

	constructor(
		private _translatesService: TranslatesService,
		private mainService: MainService,
		private router: Router,
		private readonly _meta: MetaService,
		public colorPicker: ColorScssService
	) {}

	ngOnInit(): void {
		this.langList$ = this._translatesService.getLangList();
		this.currentLang = this._translatesService.getCurrentLang();
		this.colorPicker.setColorScheme('color-1');
	}

	ngAfterViewInit(){
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				setTimeout(() => {
					this.mainService.changeImgFormat();
				}, 3000);
			}
		})
	}
	
	public changeLang(code: string): void {
		this._translatesService.changeLang(code);
	}
}
