import { Component, OnInit } from '@angular/core';
import { MainService } from '@shared/service/main.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-main-camera',
  templateUrl: './main-camera.component.html',
  styleUrls: ['./main-camera.component.scss']
})
export class MainCameraComponent implements OnInit {

	constructor(
    private mainService: MainService,
		private router: Router
  ) { }

	ngOnInit(): void {
	}

  ngAfterViewInit(){
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				setTimeout(() => {
					this.mainService.changeImgFormat();
				}, 3000);
			}
		})
  }
  
}
