<header class="dark loding-header custom-scroll">
    <div class="container">
        <nav class="pt-0">
            <a routerLink="/" class="d-inline-block m-r-auto"><img src="assets/images/logo/brand-dark.png" alt="" class="img-webp img-fluid brand-logo"></a>
            <app-menu></app-menu>
        </nav>
    </div>
</header>
<section class="single-blog-head bg-webp" data-image-src="assets/images/breadcrumb" style="background-image:url('./assets/images/breadcrumb.jpg')">
    <div class="container">
        <div class="row m-t-50 m-b-0">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="borders text-center color-000"><span>{{'helpTitle' | translate}}</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="agency blog-sec blog-sidebar single_blog_item">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="blog-text">
                    <div class="blog-description border-0 pb-0">
                        <p class="mb-3" [innerHTML]="'helpContent.0' | translate"></p>
                        <p class="mb-3">{{ "helpContent.1" | translate }}</p>
                        <p class="mb-3">
                            {{ "helpContent.2" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-3.png" alt="blog">
                        </p>
                        <p class="mb-3">
                            {{ "helpContent.3" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-4.png" alt="blog">
                        </p>
                        <p class="mb-3">
                            {{ "helpContent.4" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-5.png" alt="blog">
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-5-2.png" alt="blog">
                        </p>
                        <p class="mb-3">
                            {{ "helpContent.5" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-6.png" alt="blog">
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-6-1.png" alt="blog">
                        </p>
                        <p class="mb-3">
                            {{ "helpContent.6" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-7.png" alt="blog">
                        </p>
                        <p class="mb-3">
                            {{ "helpContent.7" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-8.png" alt="blog">
                        </p>
                        <p class="mb-3">
                            {{ "helpContent.8" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-9.png" alt="blog">
                        </p>
                        <p class="mb-3">
                            {{ "helpContent.9" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-10.png" alt="blog">
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-10-1.png" alt="blog">
                        </p>
                        <p class="mb-3">{{ "helpContent.10" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-11.png" alt="blog">
                        </p>
                        <p class="mb-3">{{ "helpContent.11" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-12.png" alt="blog">
                        </p>
                        <p class="mb-3">{{ "helpContent.12" | translate }}</p>
                        <p class="mb-3">{{ "helpContent.13" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-14.png" alt="blog">
                        </p>
                        <p class="mb-3">{{ "helpContent.14" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-15.png" alt="blog">
                        </p>
                        <p class="mb-3">{{ "helpContent.15" | translate }}
                            <img class="img-fluid blur-up lazyload img-demo img-webp" src="assets/images/help/img-16.png" alt="blog">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--footer start-->
<footer class="saas1 footer2 bg-webp" data-image-src="assets/images/saas1/banner-2" style="background-image: 'url(assets/images/saas1/banner-2.jpg)'; background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-lg-7">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">{{'footerAboutGothru' | translate}}</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">{{'footerAboutGothru' | translate}}</h5>
                    <div class="mb-0 text-white">{{'footerAboutGothruDesc' | translate}}</div>
                </div>
            </div>

            <div class="col-md-3 col-lg-2">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">{{'footerMenu' | translate}}</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">{{'footerMenu' | translate}}</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="https://gothru.co/#P" target="_BLANK">{{'footerMenuOurProduct' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/#T" target="_BLANK">{{'footerMenuOurTestimonials' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/#N" target="_BLANK">{{'footerMenuOurOurProduct' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/#O" target="_BLANK">{{'footerMenuOurOurPlan' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/termsofservice.php " target="_BLANK">{{'footerMenuOurTOS' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/privacy_policy.php " target="_BLANK">{{'footerMenuOurPrivacy' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-lg-3">
                <div class="logo-sec">
                    <div class="footer-contant">
                        <img src="assets/images/logo/brand.png" alt="" class="img-fluid footer-logo img-webp" style="max-width: 175px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">{{'footerContact1' | translate}}</h6>
                            <h6 class="text-white para-address">{{'footerContact2' | translate}}</h6>
                            <h6 class="text-white para-address">{{'email' | translate}}: <a href="mailto:info@gothru.co" class="text-white" style="text-transform: none;">info@gothru.co</a></h6>
                            <h6 class="text-white para-address">{{'email' | translate}}: <a href="mailto:support@gothru.co" class="text-white" style="text-transform: none;">support@gothru.co</a></h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="https://web.facebook.com/groups/GoThru" target="_BLANK"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="https://www.youtube.com/channel/UCVmkgq5EQR_Dls3LG-wkrjw" target="_BLANK"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<!--footer end-->

<!--copyright start-->
<div class="saas1 copyright">
    <div class="container text-center">
        <h6 class="copyright-text text-white op-text">
            Copyright ©2020 Streetbuilder <i class="fa fa-heart" aria-hidden="true"></i> GoThru
        </h6>
    </div>
</div>
<!--copyright end-->

<app-tap-to-top></app-tap-to-top>
<!--copyright end-->
