import { Component, OnInit } from '@angular/core';
import { TranslatesService, ILang } from '@shared/translates';
import { MetaService } from '@ngx-meta/core';
import { MainService } from '@shared/service/main.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
	public contents = [];

	constructor(
		private _translatesService: TranslatesService,
		private readonly _meta: MetaService,
		private mainService: MainService,
		private router: Router
	) {}

	ngOnInit(): void {
	}

	ngAfterViewInit(){
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				setTimeout(() => {
					this.mainService.changeImgFormat();
				}, 3000);
			}
		})
	}
}
