import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-enterprice-sass-work',
  templateUrl: './enterprice-sass-work.component.html',
  styleUrls: ['./enterprice-sass-work.component.scss']
})
export class EnterpriceSassWorkComponent implements OnInit {
	public work: number = 0;
	public navUsage: [];
	public contentUsage: [];

	constructor() {}

	ngOnInit() {}

	openWorkSlide(val){
		this.work = val
	}
}
