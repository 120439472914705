<!-- header section Start-->
<section class="saas1 header" id="home">
    <div class="saas1-header bg header8-content bg-webp" data-image-src="assets/images/saas1/slider-banner" [ngStyle]="{'background-image': 'url(assets/images/saas1/slider-banner.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <div class="d-flex">
                                    <h1>StreetBuilder</h1>
                                    <div class="center-content slider-logo">
                                        <img src="assets/images/saas1/slider-logo.png" class="img-fluid img-webp" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="header-sub-text">
                                <h3 class="text-white">{{'homeContent1' | translate}}</h3>
                            </div>
                            <div class="link-horizontal">
                                <ul>
                                    <li>
                                        <a class="btn btn-default primary-btn transparent btn-home" href="https://gothru.co/GoThru-Street-Builder-1.1.6.dmg">
                                            <i class="fa fa-apple mr-2"></i> <span [innerHTML]="'homeBtn2' | translate"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="btn btn-default primary-btn transparent btn-home" href="https://gothru.co/GoThru-Street-Builder-Setup-1.1.6.exe">
                                            <i class="fa fa-windows mr-2"></i>  <span [innerHTML]="'homeBtn3' | translate"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="center-text slider-banner">
                        <img class="img-webp" src="assets/images/saas1/slider-laptop3.png" alt="slid-banner">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/saas1/background2.png" class="img-webp img-fluid set-abs background" alt="">
    <img src="assets/images/saas1/dot.png" class="img-webp img-fluid set-abs dot" alt="">
</section>
<!-- header section end-->