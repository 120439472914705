<!--footer start-->
<footer class="saas1 footer2 bg-webp" data-image-src="assets/images/saas1/banner-2" style="background-image: 'url(assets/images/saas1/banner-2.jpg)'; background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-lg-7">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">{{'footerAboutGothru' | translate}}</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">{{'footerAboutGothru' | translate}}</h5>
                    <div class="mb-0 text-white">{{'footerAboutGothruDesc' | translate}}</div>
                </div>
            </div>

            <div class="col-md-3 col-lg-2">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">{{'footerMenu' | translate}}</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">{{'footerMenu' | translate}}</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="https://gothru.co/#P" target="_BLANK">{{'footerMenuOurProduct' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/#T" target="_BLANK">{{'footerMenuOurTestimonials' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/#N" target="_BLANK">{{'footerMenuOurOurProduct' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/#O" target="_BLANK">{{'footerMenuOurOurPlan' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/termsofservice.php " target="_BLANK">{{'footerMenuOurTOS' | translate}}</a></li>
                            <li class=""><a href="https://gothru.co/privacy_policy.php " target="_BLANK">{{'footerMenuOurPrivacy' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-md-4 col-lg-3">
                <div class="logo-sec">
                    <div class="footer-contant">
                        <img src="assets/images/logo/brand.png" alt="" class="img-webp img-fluid footer-logo" style="max-width: 175px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">{{'footerContact1' | translate}}</h6>
                            <h6 class="text-white para-address">{{'footerContact2' | translate}}</h6>
                            <h6 class="text-white para-address">{{'email' | translate}}: <a href="mailto:info@gothru.co" class="text-white" style="text-transform: none;">info@gothru.co</a></h6>
                            <h6 class="text-white para-address">{{'email' | translate}}: <a href="mailto:support@gothru.co" class="text-white" style="text-transform: none;">support@gothru.co</a></h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="https://web.facebook.com/groups/GoThru" target="_BLANK"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="https://www.youtube.com/channel/UCVmkgq5EQR_Dls3LG-wkrjw" target="_BLANK"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
