<a  class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>

<div class="responsive-btn">
    <div class="wrap-language-responsive dropdown">
        <a href="javascript:;" id="dropdownBasic1" (click)="showDropdown = !showDropdown;">
            <img class="img-webp" [src]="'../../../../../assets/images/flag/'+ currentLang +'.png'" alt=""/>
            <span style="text-transform: uppercase;">{{currentLang}}</span>
        </a>
        <div class="dropdown-menu" [class.show]="showDropdown">
            <div class="wrap-link-locale">
                <a 
                    *ngFor="let item of langList$ | async" 
                    (click)="changeLang(item.code); showDropdown = false;" 
                    class="link-locale" 
                    [class.active]="item.code === currentLang" 
                    href="javascript:;">
                    <img class="img-webp" [src]="'assets/images/flag/'+ item.code +'.png'" alt=""/>
                </a>
            </div>
        </div>
    </div>
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide" >
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>back</h5>
        </a>
    </div>
    <ul class="main-menu">
        <li><a href="javascript:void(0)" (click)="scrollTo('home')">{{ 'menuHome' | translate }}</a></li>
        <li><a href="javascript:void(0)" (click)="scrollTo('feature')">{{ 'menuFeatures' | translate }}</a></li>
        <li><a href="javascript:void(0)" (click)="scrollTo('about')">{{ 'menuAbout' | translate }}</a></li>
        <li><a href="javascript:void(0)" (click)="scrollTo('usage')">{{ 'menuUsage' | translate }}</a></li>
        <li>
            <a href="javascript:void(0)" class="dropdown" (click)="openSubMenu0 = !openSubMenu0">
                {{ 'menuCamera' | translate }}
            </a>
            <ul  [class.opensubmenu]="openSubMenu0">
                <li class="sub-menu">
                    <a href="javascript:void(0)"  (click)="openSubMenu3 = !openSubMenu3">GoPro</a>
                    <ul [class.opensubmenu]="openSubMenu3">
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/gopro-fusion">Fusion</a></li>
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/gopro-fusion-max">Fusion Max</a></li>
                    </ul>
                </li>
                <li class="sub-menu">
                    <a href="javascript:void(0)" (click)="openSubMenu2 = !openSubMenu2">Insta360</a>
                    <ul [class.opensubmenu]="openSubMenu2">
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/insta360-one-x">One X</a></li>
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/insta360-pro1">Pro 1</a></li>
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/insta360-pro2">Pro 2</a></li>
                    </ul>
                </li>
                <li class="sub-menu">
                    <a href="javascript:void(0)"  (click)="openSubMenu5 = !openSubMenu5">Labpano</a>
                    <ul [class.opensubmenu]="openSubMenu5">
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/labpano-pilot-era">Pilot ERA</a></li>
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/labpano-pilot-one">Pilot One</a></li>
                    </ul>
                </li>
                <li class="sub-menu">
                    <a href="javascript:void(0)"  (click)="openSubMenu4 = !openSubMenu4;">NCTech</a>
                    <ul [class.opensubmenu]="openSubMenu4">
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/nctech-istar-pulsar">iSTAR Pulsar</a></li>
                    </ul>
                </li>
                <li class="sub-menu">
                    <a href="javascript:void(0)" (click)="openSubMenu1 = !openSubMenu1">Ricoh</a>
                    <ul [class.opensubmenu]="openSubMenu1">
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/ricoh-theta-s">Theta S</a></li>
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/ricoh-theta-v">Theta V</a></li>
                        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/cameras/ricoh-theta-z1">Theta Z1</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><a href="javascript:void(0)" (click)="scrollTo('pricing')">{{ 'menuPricing' | translate }}</a></li>
        <li><a href="javascript:void(0)" routerLinkActive="theme-color-basic" routerLink="/help">{{ 'menuHelp' | translate }}</a></li>
    </ul>  

    <div class="wrap-language dropdown">
        <a href="javascript:;" id="dropdownBasic1" (click)="showDropdown = !showDropdown;"> 
            <img class="img-webp" [src]="'assets/images/flag/'+ currentLang +'.png'" alt=""/>
            <span style="text-transform: uppercase;">{{currentLang}}</span>
        </a>
        <div class="dropdown-menu" [class.show]="showDropdown">
            <div class="wrap-link-locale">
                <a 
                    *ngFor="let item of langList$ | async" 
                    (click)="changeLang(item.code); showDropdown = false;" 
                    class="link-locale" 
                    [class.active]="item.code === currentLang"
                    href="javascript:;">
                    <img class="img-webp" [src]="'assets/images/flag/'+ item.code +'.png'" alt=""/>
                </a>
            </div>
        </div>
    </div>
</div>
  
