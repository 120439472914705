import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-feature',
  templateUrl: './enterprice-sass-feature.component.html',
  styleUrls: ['./enterprice-sass-feature.component.scss']
})
export class EnterpriceSassFeatureComponent implements OnInit {
	
	constructor(){}

	ngOnInit() {}

	ngAfterViewInit(){}
}
