

<!-- feature section-->
<section id="feature" class="saas1 service" style="background-color: #ffffff;">
    <div class="about-chat">
        <div class="container">
            <div class="title">
                <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-webp img-fluid">
                <div class="main-title">
                    <h2 [innerHTML]="'featureTitle' | translate"></h2>
                </div>
                <hr>
                <div class="sub-title">
                    <p class="p-padding mb-2" [innerHTML]="'featureContent' | translate"></p>
                </div>
            </div>

            <div class="row">

                <div class="col-md-4 service-container">
                    <div class="chat-box">
                        <img class="img-webp" src='assets/images/feature/img1.png' alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading" [innerHTML]="'contentFeatures.0.title' | translate"></h4>
                            <hr>
                            <p [innerHTML]="'contentFeatures.0.description' | translate"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 service-container">
                    <div class="chat-box">
                        <img class="img-webp" src='assets/images/feature/img2.png' alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading" [innerHTML]="'contentFeatures.1.title' | translate"></h4>
                            <hr>
                            <p [innerHTML]="'contentFeatures.1.description' | translate"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 service-container">
                    <div class="chat-box">
                        <img class="img-webp" src='assets/images/feature/img3.png' alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading" [innerHTML]="'contentFeatures.2.title' | translate"></h4>
                            <hr>
                            <p [innerHTML]="'contentFeatures.2.description' | translate"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 service-container">
                    <div class="chat-box">
                        <img class="img-webp" src='assets/images/feature/img4.png' alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading" [innerHTML]="'contentFeatures.3.title' | translate"></h4>
                            <hr>
                            <p [innerHTML]="'contentFeatures.3.description' | translate"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 service-container">
                    <div class="chat-box">
                        <img class="img-webp" src='assets/images/feature/img5.png' alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading" [innerHTML]="'contentFeatures.4.title' | translate"></h4>
                            <hr>
                            <p [innerHTML]="'contentFeatures.4.description' | translate"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 service-container">
                    <div class="chat-box">
                        <img class="img-webp" src='assets/images/feature/img6.png' alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading" [innerHTML]="'contentFeatures.5.title' | translate"></h4>
                            <hr>
                            <p [innerHTML]="'contentFeatures.5.description' | translate"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end feature section -->

<section id="about" class="saas1 build-bg bg-webp" data-image-src="assets/images/saas1/banner-2" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container mb-0 mt-0">
        <div class="row">
            <div class="col-lg-5">
                <div class="build-box">
                    <h3 class="build-head text-white" [innerHTML]="'aboutTitle' | translate"></h3>
                    <p class="text-white mb-5" [innerHTML]="'aboutContent' | translate"></p>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/395963658?title=0&byline=0&portrait=0" allowfullscreen></iframe>
                </div>
                <div class="build-box">
                    <p class="text-white mt-4"[innerHTML]="'aboutVideo' | translate"></p>
                </div>
            </div>
        </div>
    </div>
</section>

