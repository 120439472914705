<!-- work section -->
<section id="usage" class="saas1 howitwork bg-webp" data-image-src="assets/images/saas1/work-bg" [ngStyle]="{'background-image': 'url(assets/images/saas1/work-bg.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="title">
                    <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-webp img-fluid">
                    <div class="main-title">
                        <h2 [innerHTML]="'titleUsage' | translate"></h2>
                    </div>
                    <hr>
                    <div class="sub-title">
                        <p class="mb-0" [innerHTML]="'subTitleUsage' | translate"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tab">
            <ul class="nav nav-pills justify-content-center " id="pills-tab" role="tablist">
                <li class="nav-item text-center">
                    <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work === 0 }" (click)="openWorkSlide(0)">
                        <img class="img-webp" src="assets/images/saas1/tab-icon/img01.png" alt="tab-image-1">
                        <h6 [innerHTML]="'navUsage.0.title' | translate"></h6>
                        <span></span>
                    </a>
                </li>
                <li class="nav-item text-center">
                    <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work === 1 }" (click)="openWorkSlide(1)">
                        <img class="img-webp" src="assets/images/saas1/tab-icon/img02.png" alt="tab-image-1">
                        <h6 [innerHTML]="'navUsage.1.title' | translate"></h6>
                        <span></span>
                    </a>
                </li>
                <li class="nav-item text-center">
                    <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work === 2 }" (click)="openWorkSlide(2)">
                        <img class="img-webp" src="assets/images/saas1/tab-icon/img03.png" alt="tab-image-1">
                        <h6 [innerHTML]="'navUsage.2.title' | translate"></h6>
                        <span></span>
                    </a>
                </li>
                <li class="nav-item text-center">
                    <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work === 3 }" (click)="openWorkSlide(3)">
                        <img class="img-webp" src="assets/images/saas1/tab-icon/img04.png" alt="tab-image-1">
                        <h6 [innerHTML]="'navUsage.3.title' | translate"></h6>
                        <span></span>
                    </a>
                </li>
                <li class="nav-item text-center">
                    <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work === 4 }" (click)="openWorkSlide(4)">
                        <img class="img-webp" src="assets/images/saas1/tab-icon/img05.png" alt="tab-image-1">
                        <h6 [innerHTML]="'navUsage.4.title' | translate"></h6>
                        <span></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="container">
        <div class="work-tab-bg work-content p-t-50">
            <div class="tab-content text-center" id="pills-tabContent">
                <div class="tab-pane fade" [ngClass]="{'active show': work === 0 }" id="pills-work1" role="tabpanel">
                    <img src="assets/images/saas1/tab/img01.png" alt="tab-image-1" class="img-fluid img-webp">
                </div>
                <div class="tab-pane fade" [ngClass]="{'active show': work === 1 }" id="pills-work1" role="tabpanel">
                    <img src="assets/images/saas1/tab/img02.png" alt="tab-image-1" class="img-fluid img-webp">
                </div>
                <div class="tab-pane fade" [ngClass]="{'active show': work === 2 }" id="pills-work1" role="tabpanel">
                    <img src="assets/images/saas1/tab/img03.png" alt="tab-image-1" class="img-fluid img-webp">
                </div>
                <div class="tab-pane fade" [ngClass]="{'active show': work === 3 }" id="pills-work1" role="tabpanel">
                    <img src="assets/images/saas1/tab/img04.png" alt="tab-image-1" class="img-fluid img-webp">
                </div>
                <div class="tab-pane fade" [ngClass]="{'active show': work === 4 }" id="pills-work1" role="tabpanel">
                    <img src="assets/images/saas1/tab/img05.png" alt="tab-image-1" class="img-fluid img-webp">
                </div>
            </div>
        </div>
    </div>
    

</section>
<!-- end work section -->
