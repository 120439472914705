import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
	
	constructor(
		private http: HttpClient
	) { }

	getJsonData(){
		const options = {
			headers: new HttpHeaders({'accept':  'application/json'})
		};
		return this.http.get(`./assets/version.json`,options);
		// return this.http.get(`https://gothru.co/version.json`,options);
	}
}
