import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/home/home.component';
import { HelpComponent } from '../app/pages/help/help.component';
import { MainCameraComponent } from './pages/cameras/main-camera/main-camera.component';
import { NotFoundComponent } from '../app/not-found/not-found.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
	},
	{
		path: 'help',
		component: HelpComponent,
		loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule)
	},
	{
		path: 'cameras',
		component: MainCameraComponent,
		loadChildren: () => import('./pages/cameras/cameras.module').then(m => m.CamerasModule)
	},
	{
		path: '**',
		component: NotFoundComponent,
		loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
	},
];
// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, { initialNavigation: 'enabled' });
