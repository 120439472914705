<!-- testimonial section -->
<section class="agency testimonial format testimonial-bg bg-webp" data-image-src="assets/images/saas1/banner-2" style="background-image: 'url(assets/images/saas1/banner-2.jpg)'; background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class="format-container m-b-50">
                    <div class="format-head-text">
                        <h2 class="text-white" [innerHTML]="'titleTestimonial' | translate"></h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="owl-carousel owl-theme testimonial-slider">
                    <div class="item" *ngFor="let user of users">
                        <div class="testimonial-container testimonial-container2 m-0">
                            <div class="d-flex">
                                <img class="img-webp" [src]="user.img" alt="" class="img-fluid m-r-25">
                                <div class="center-content">
                                    <h6 class="name">{{user.name}}</h6>
                                    <a [href]="user.link" target="_BLANK" class="font-primary" style="text-transform: none;">{{user.designation}}</a>
                                </div>
                            </div> 
                            <div class="testimonial-info">
                                <p class="m-b-20 para color-000 text-left">{{user.review}}</p>
                                <div class="primary-border"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>