import { Component, OnInit } from '@angular/core';
import { MainService } from '@shared/service/main.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
	public status: { code: number; message: string };

	constructor(
		private mainService: MainService,
		private router: Router
	) {}

	ngOnInit(): void {
	}

	ngAfterViewInit(){
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				setTimeout(() => {
					this.mainService.changeImgFormat();
				}, 3000);
			}
		})
	}
}
